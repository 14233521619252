import ReactDOM from "react-dom/client"
import { useEffect } from "react";
import { App } from "./app"
import { BrowserRouter, useLocation } from "react-router-dom"

function ScrollToTop({ children }) {
    const { pathname } = useLocation();
    
    useEffect(() => {
      const canControlScrollRestoration = 'scrollRestoration' in window.history
      if (canControlScrollRestoration) {
        window.history.scrollRestoration = 'manual';
      }
  
      window.scrollTo(0, 0);
    }, [pathname]);
    
    return children;
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <ScrollToTop/>
        <App />
    </BrowserRouter>
)