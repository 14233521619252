let navData = {
    "navImg": "ga-logo.png",
}

let headerData = {
    "headerImg": "header-img.png",
    "headerLogo": "header-logo.png"
}

let heroData = {
    "heroImg": "hero-img.png"
}

let displayData = [
    {'link': 'large_bowl.png', 'title': 'Dish'},
    {'link': 'teapot.png', 'title': 'Teapots'},
    {'link': 'bowl.png', 'title': 'Bowls'},
    {'link': 'cup.png', 'title': 'Mugs'},
    {'link': 'pestle_mortar.png', 'title': 'Pestle & Mortar'},
    {'link': 'sake_set.png', 'title': 'Sake Sets'},
    // {'link': 'condiment_bowl.png', 'title': 'Condiment Bowls'},
    // {'link': 'set.jpeg', 'title': 'More Sets'},
    {'link': 'bowl.png', 'title': 'Plates'},
    {'link': 'bowl.png', 'title': 'Mugs'}
]


export let data = {
    navData,
    headerData,
    heroData,
    displayData
}
