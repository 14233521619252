import { Biography } from "../components/biography"
import { Nav } from "../components/nav"
import { data } from "../data"

export function About() {
    return (
        <>
        <Nav navImg={data.navData.navImg} navHeader={data.navData.navHeader} />        
        <Biography />
        </>
    )
}