import { Link } from "react-router-dom";

export function Header(props){
    return (
        <>
        <div className="header--button--wrapper">
            <Link to="/">
                <h3 className="header--buttons">My Work</h3>
            </Link>
            <Link to="/about">
                <h3 className="header--buttons">About</h3>
            </Link>
            <h3 className="header--buttons">Contact</h3>
        </div>
        <img className="header--img" src={require(`../images/${props.headerImg}`)}></img>
        <img className="header--logo" src={require(`../images/${props.headerLogo}`)}></img>
        </>
    )
}