import React, { useState, useEffect } from "react";
import "../styles/nav.css";
import { Link } from "react-router-dom";

export function Nav(props) {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 950);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    let MiniNav = () => {
        return (
            <nav id="navbar" className="navbar">
                <Link to="/">
                    <img className="navbar--img" src={require(`../images/${props.navImg}`)} alt="Nav Img" />
                </Link>
                <div className="navbar--button--wrapper">
                    <button className="navbar--button">Store</button>
                    <Link to="/about">
                        <button className="navbar--button">About</button>
                    </Link>
                    <button className="navbar--button">Contact</button>
                </div>
            </nav>
        )
    };

    return (
        <>
            <div className="about--header--wrapper">
                <img className="about--header" src={require(`../images/canvaswell.png`)} alt="Canvaswell" />
                <Link to="/">
                    <img className="about--header--logo" src={require(`../images/header-logo.png`)} alt="Header Logo" />
                </Link>
            </div>
            {isScrolled && <MiniNav />}
        </>
    );
}